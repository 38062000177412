import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { useTranslation } from "react-i18next";
import { Controller, useForm} from 'react-hook-form';
import { Button } from "primereact/button";
import { useEffect, useRef, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Panel } from "primereact/panel";
import findServico from "../../service/findServico";
import { SortOrder } from "primereact/api";
import { InputNumber } from "primereact/inputnumber";
import { Calendar } from "primereact/calendar";
import { Messages } from "primereact/messages";
import { useGlobalState } from "../../components/globalState/GlobalStateProvider";


export default function PlanoCadastrar({plano, onClose, onSave}:{plano:any, onClose: () => void, onSave: (plano:any) => void}) {
  const { t } = useTranslation();
  const [showPlanoServico, setShowPlanoServico] = useState<boolean>(false);
  const [showPlanoServicoValor, setShowPlanoServicoValor] = useState<boolean>(false);
  const [planosServico, setPlanosServico] = useState<any[]>(plano?.planosServico);
  const [planosServicoValor, setPlanosServicoValor] = useState<any[]>();
  const [valor, setValor] = useState<number|null|undefined>(null);
  const [data, setData] = useState<Date|null|undefined>(null);
  const [servicos, setServicos] = useState<any>([]);
  const [selectedServico, setSelectedServico] = useState<any>();
  const msgs = useRef<Messages>(null);
  const [planoServicoValorEdit, setPlanoServicoValorEdit] = useState<any>();
  const [planoServicoEdit, setPlanoServicoEdit] = useState<any>();
  const {state} = useGlobalState();
  const defaultValues = {
    descricao: ''
  };
  defaultValues.descricao = plano.descricao ? plano.descricao.toString() : ''
 
  useEffect(()=>{
    const lazyServico = {
      first: 0,
      rows: 99999999,
      page: 1,
      idFranquia : state.user.franquiaSelected?.id,
      sortField: 'descricao',
      sortOrder: SortOrder.DESC
    };
    findServico(lazyServico).then((data) => setServicos(data.servicos))
  }, [])
  
  const {
      control,
      formState: { errors},
      handleSubmit
  } = useForm({ defaultValues });

  const onSubmit = (data:any) => {
      data.descricao && save(data);
  };

const salvarServico = () => {
  if(selectedServico && planosServicoValor && planosServicoValor.length > 0){
    const lista = []
    planosServico?.forEach((p) => lista.push(p))
    const results = planosServico?.filter((p) => p.servico.tipoBilhete === selectedServico.tipoBilhete)
    if(results && results.length !== 0 && (!planoServicoEdit || planoServicoEdit.servico.tipoBilhete !== selectedServico.tipoBilhete)){
      msgs.current?.clear();
      msgs.current?.show([
          { sticky: false, life: 10000, severity: 'error', summary: 'Erro', detail: 'Serviço já existe', closable: false }
         
      ]);
      window.scrollTo(0, 0);
    }
    else{
      if(!planoServicoEdit){
        lista.push({servico:selectedServico, planosServicoValor:planosServicoValor})
      }
      else{
        planoServicoEdit.planosServicoValor = planosServicoValor;
        planoServicoEdit.servico = selectedServico;
      }
      setPlanoServicoEdit(null)
      setPlanosServico(lista)
      setSelectedServico(null)
      setPlanosServicoValor([])
      setShowPlanoServico(false)
    }
  }
  else{
    msgs.current?.clear();
      msgs.current?.show([
          { sticky: false, life: 10000, severity: 'error', summary: 'Erro', detail: 'Serviço e valores obrigatórios', closable: false }
         
      ]);
      window.scrollTo(0, 0);
  }
}
 
 const salvarValor = () => {
  if(data && valor){
    const lista = []
    planosServicoValor?.forEach((p) => lista.push(p))
    const results = planosServicoValor?.filter((p) => formatDate(p.data) === formatDate(data))
    if(results && results.length !== 0 && (!planoServicoValorEdit || formatDate(planoServicoValorEdit.data) !== formatDate(data))){
      msgs.current?.clear();
      msgs.current?.show([
          { sticky: false, life: 10000, severity: 'error', summary: 'Erro', detail: 'Data já existe', closable: false }
         
      ]);
      window.scrollTo(0, 0);
    }
    else{
      if(!planoServicoValorEdit){
        lista.push({data:data, valor:valor})
      }
      else{
        planoServicoValorEdit.valor = valor;
        planoServicoValorEdit.data = data;
        
      }
      setPlanoServicoValorEdit(null)
      setPlanosServicoValor(lista)
      setData(null)
      setValor(null)
      setShowPlanoServicoValor(false)
    }
    
  }
  else{
    if (msgs.current) {
      msgs.current?.clear();
      msgs.current?.show([
          { sticky: false, life: 10000, severity: 'error', summary: 'Erro', detail: 'Data e valor são obrigatórios', closable: false }
         
      ]);
      window.scrollTo(0, 0);
    }
  }
  
 }
 const formatDate = (value: { toLocaleDateString: (arg0: string, arg1: { day: string; month: string; year: string; }) => any; }) => {
  return value.toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
  });
};

const bodyValor = (item:any) => {
  return item.planosServicoValor[item.planosServicoValor.length - 1].valor
}

 const dateBodyTemplate = (rowData: { data: any; }) => {
  return formatDate(rowData.data);
  };
 
  const save = (data:any) => {
    const plan = {
      id: plano.id? plano.id : undefined,
      descricao:data.descricao,
      planosServico: planosServico
    }
    window.scrollTo(0, 0);
    onSave(plan);
  }

  const editValorBodyTemplate = (item:any) => {
    return <>
            <Button icon="pi pi-pencil" type="button" onClick={() => {setData(item.data); setValor(item.valor);setPlanoServicoValorEdit(item);setShowPlanoServicoValor(true)}} style={{background:'transparent', color:'gray', border: 'none'}} />
            <Button icon="pi pi-trash" type="button" onClick={() => {setPlanosServicoValor(planosServicoValor?.filter((v) => formatDate(v.data) !== formatDate(item.data)))}} style={{background:'transparent', color:'gray', border: 'none'}} />
          </>
  };

  const editServicoBodyTemplate = (item:any) => {
    return <>
      <Button icon="pi pi-pencil" type="button" onClick={(e) => {setSelectedServico(servicos.filter((s: any) => s.descricao === item.servico.descricao)[0] ); setPlanosServicoValor(item.planosServicoValor);setPlanoServicoEdit(item);setShowPlanoServico(true);}} style={{background:'transparent', color:'gray', border: 'none'}} />
      <Button icon="pi pi-trash" type="button" onClick={() => {setPlanosServico(planosServico?.filter((s) => s.servico.tipoBilhete !== item.servico.tipoBilhete))}} style={{background:'transparent', color:'gray', border: 'none'}} />
    </>
  };
  
  const getFormErrorMessage = (name:string) => {
    type ObjectKey = keyof typeof errors;
    const error = errors[Object.keys(errors).find(k => k === name) as ObjectKey]
    return error ? <small className="p-error">{error.message}</small> : <small className="p-error">&nbsp;</small>;
  };

  const footerTableValor = (
    <React.Fragment>
      {!showPlanoServicoValor && (
          <div style={{display:"flex", justifyContent:"flex-end", paddingTop:"1rem"}} className=" flex">
            <Button form='form-permission' style={{marginRight:"1rem", backgroundColor:'#183462'}} label="Adicionar Valor" onClick={()=>setShowPlanoServicoValor(true)} />
          </div>
      )}
      {showPlanoServicoValor && (
            <Panel>
              <div className="grid">
                <div className="col p-fluid flex flex-wrap">
                  <label htmlFor="dataFinal" className="font-bold block mb-2 required">{t('data')} </label>
                  <Calendar id='dataFinal' value={data} onChange={(e) => setData(e.value)} showIcon dateFormat="dd/mm/yy" locale='pt'/>
                </div>
                <div className="col p-fluid flex flex-wrap">
                    <label htmlFor="servico" className="font-bold block mb-2 required">{t('valor')} </label>
                    <InputNumber inputId="currency" value={valor} onValueChange={(e) => setValor(e.value)} mode="currency" currency="BRL" locale="pt-BR" />
                  </div>
              </div>
               <div style={{display:"flex", justifyContent:"flex-end", paddingTop:"1rem"}} className=" flex">
                <Button form='form-permission' style={{marginRight:"1rem", backgroundColor:'#183462'}} label="Salvar Valor" onClick={() => salvarValor()}  />
                <Button label="Fechar" icon="pi pi-times" style={{color:'#183462'}} onClick={() => setShowPlanoServicoValor(false)} className="p-button-text" />
              </div>
            </Panel>
      )}
    </React.Fragment>
  );

  const paginatorRight = (
    <React.Fragment>
        {!showPlanoServico && (
          <div style={{display:"flex", justifyContent:"flex-end", paddingTop:"1rem"}} className=" flex">
            <Button form='form-permission' style={{marginRight:"1rem", backgroundColor:'#183462'}} label="Adicionar Serviço" onClick={()=>setShowPlanoServico(true)} />
          </div>
          )
        }
          {showPlanoServico && (
            <Panel>
               
                <div className="grid">
                  <div className="col p-fluid flex flex-wrap">
                  <label htmlFor="servico" className="font-bold block mb-2 required">{t('servico')} </label>
                    <Dropdown style={{width: '50%'}} value={selectedServico} onChange={(e) => setSelectedServico(e.value)} options={servicos} optionLabel="descricao" placeholder="Selecione Serviço" 
                        filter className=" md:w-14rem" />  
                  </div>
                </div>
                <br></br>
                <DataTable size="small" footer={footerTableValor} value={planosServicoValor} tableStyle={{ minWidth: '50rem' }}>
                  <Column  body={dateBodyTemplate} dataType="Date" header={t('data')}></Column>
                  <Column field="valor" header={t('valor')}></Column>
                  <Column body={editValorBodyTemplate} />
                </DataTable>
              {!showPlanoServicoValor && (
                <div style={{display:"flex", justifyContent:"flex-end", paddingTop:"1rem"}} className=" flex">
                  <Button form='form-permission' style={{marginRight:"1rem", backgroundColor:'#183462'}} label="Salvar Serviço" onClick={()=>salvarServico()} />
                  <Button label="Fechar" icon="pi pi-times" style={{color:'#183462'}} onClick={() => setShowPlanoServico(false)} className="p-button-text" />
                </div>
              )}
             
            
            </Panel>
          )}
          
    </React.Fragment>
    );

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-column gap-2">
        <Messages ref={msgs} />
        <div>
          <div className="grid">
              <div className="col p-fluid flex flex-wrap">
                
                  <Controller
                      name="descricao"
                      control={control}
                      rules={{ required: 'Descrição é Obrigatório' }}
                      render={({ field, fieldState }) => (
                          <>
                              <label htmlFor={field.name} className={classNames({ 'p-error': errors.descricao })}></label>
                              <span className="">
                              <div>
                                <label htmlFor="descricao" className="font-bold block mb-2 required">{t('descricao')} </label>
                                <InputText style={{width:'100%'}} id={field.name} value={field.value} className={classNames({ 'p-invalid': fieldState.error })} onChange={(e) => field.onChange(e.target.value)} />
                                </div>
                              </span>
                              {getFormErrorMessage(field.name)}
                          </>
                      )}
                  />
                    
               
              </div>
              <div className="col">
                
               
              </div>
          </div>
          
       
        </div>
        <br></br>
        <DataTable size="small" paginatorRight={paginatorRight} footer={paginatorRight} value={planosServico} tableStyle={{ minWidth: '50rem' }}>
          <Column field="servico.descricao" header={t('servico')}></Column>
          <Column body={bodyValor} header={t('valor')}></Column>
          <Column body={editServicoBodyTemplate} />
        </DataTable>
        <br></br>
        <div style={{display:"flex", justifyContent:"flex-end", paddingTop:"1rem"}} className=" flex">
            <Button style={{marginRight:"1rem", backgroundColor:'#183462'}} label="Salvar" type="submit"   />
            <Button icon="pi pi-times" onClick={onClose} rounded outlined severity="danger" aria-label="Cancel" />
        </div> 
    </form>
    
  )
}



